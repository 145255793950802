
  import { defineComponent,ref,reactive,toRefs,onMounted} from 'vue'
  import { useRoute,useRouter } from 'vue-router'
  import storage from '@/assets/js/localStorage'
  import { Icon, Toast,Dialog} from 'vant'
import HeaderVue from '@/components/shopping/Header.vue'
import api from '@/assets/js/api'
  export default defineComponent({
    name: '',
    props: {},
    components: {
        HeaderVue,
        [Icon.name]: Icon,
         [Toast.name]: Toast,
         [Dialog.name]: Dialog
    },
    setup: () => {
        const isSuccessFont = ref('体验更多好物和平台优质服务 (੭ >᎑<)੭')
        let pageData:any = reactive({
            orderInfo: {
                getData: false
            },
        })
        storage.setPayDetailBack(0)
        const route = useRoute()
        const router = useRouter()
        // 页面信息
        const getInfoHandle= async(id)=>{
                Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner'
            }); 
            try{
                let res = await api.payStatusApi({tradeNo: id || route.params.id,obtainItemIds:true })
                res.data.getData = res.data.order_status === 1 || res.data.order_status === -1 ? true : false
                res.data.totalAmountRmb = storage.getOrderInfo()?.totalAmountRmb || 0
                pageData.orderInfo = res.data
                Toast.clear()        
            } catch(err){

                Toast.clear()   
                if(err.length){
                    Toast(err)
                }   
                
        }
            
        }
        getInfoHandle(0)
        // 订单详情
        const goOrderDetailHandle =async ()=>{
             storage.setOrderParams({formType: 'unpaid',itemIds:route.params.id,payItemIds: pageData.orderInfo.itemIds})
            router.push({name: 'orderdetail',params:{service: 'item',id: pageData.orderInfo.itemIds || route.params.id}})
            // await api.orderDetailApi({service: 'item',itemId: pageData.orderInfo.itemIds})
        }
        // storage.setOrderParams({formType: 'unpaid',itemIds:route.params.id,payItemIds: pageData.orderInfo.itemIds})
        // 重新支付
        const payAgainHandle = async()=>{
            // 重新支付 回到计算页
            // console.log(route.params.id, pageData.orderInfo.itemIds)
            storage.setOrderParams({formType: 'unpaid',itemIds:route.params.id,payItemIds: pageData.orderInfo.itemIds})
            router.push('/shopping/paydetail')
        }
        return {
            isSuccessFont,
            goOrderDetailHandle,
            payAgainHandle,
            ...toRefs(pageData)
        }
    }
  })
